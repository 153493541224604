import { AppConfig } from '@abcfinlab/core';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'l7-slb-quote-detail-card',
    templateUrl: './slb-quote-detail-card.component.html',
    styleUrls: ['./slb-quote-detail-card.component.scss'],
})
export class SlbQuoteDetailCardComponent {

    @Input() completed: boolean;
    @Input() quoteId: string;
    private readonly hostUrl: string;
    constructor(private readonly appConfig: AppConfig) {
        this.hostUrl = appConfig.get('host');
    }

    iconCheck = 'Check_Circle_Filled';
    iconExclamation = 'Alert_Circle_Filled';
    iconDownload = 'Import_2';

    get pdf_download_link(): string {
        return btoa(`${this.hostUrl}/api/v1/slb` + `/pdf/draft/${this.quoteId}`);
    }

}
