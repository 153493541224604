import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContractTypeId } from '../../../../models/enums/ContractTypeId.enum';
import { ValueToCalculate } from '../../../../models/enums/ValueToCalculate.enum';

@Component({
    selector: 'l7-function-resolve',
    templateUrl: './function-resolve.component.html',
    styleUrls: ['./function-resolve.component.scss'],
})
export class FunctionResolveComponent {

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
    }

    @Input() contractType: ContractTypeId;

    @Input() selectedResolveParam: ValueToCalculate;

    @Input() disableFirstRate: boolean;

    @Input() disableLastRate: boolean;

    @Output() functionParam: EventEmitter<ValueToCalculate> = new EventEmitter<ValueToCalculate>();

    public readonly INSTALMENT: ValueToCalculate = ValueToCalculate.INSTALMENT;
    public readonly DOWN_PAYMENT: ValueToCalculate = ValueToCalculate.DOWN_PAYMENT;
    public readonly RESIDUAL_VALUE: ValueToCalculate = ValueToCalculate.RESIDUAL_VALUE;
    public readonly YEARLY_INTEREST: ValueToCalculate = ValueToCalculate.YEARLY_INTEREST;
    public readonly FIRST_INSTALMENT: ValueToCalculate = ValueToCalculate.FIRST_INSTALMENT;
    public readonly LAST_INSTALMENT: ValueToCalculate = ValueToCalculate.LAST_INSTALMENT;
    private _disabled: boolean;

    get is_disabled(): boolean {
        return this._disabled;
    }

    get is_hire_purchase_contract(): boolean {
        return this.contractType === ContractTypeId.MIETKAUF;
    }

    get is_va_contract() {
        return this.contractType === ContractTypeId.VA;
    }

    public changeResolveParam(parameter: ValueToCalculate, evt: MouseEvent): void | Error {
        evt.stopPropagation();
        this.selectedResolveParam = parameter;
        this.functionParam.emit(parameter);
    }

}
