/* apps/shell/src/app/private/components/calculator/slb-code-selection-form/slb-code-selection-form.component.scss */
::ng-deep #select_slb_code-panel {
  max-height: 350px;
  border-radius: 0;
}
::ng-deep #select_slb_code-panel .mat-mdc-option {
  padding: 10px 0 5px 15px;
  height: 65px !important;
}
::ng-deep #select_slb_code-panel .mat-mdc-option .mdc-list-item__primary-text p {
  line-height: 1.1em;
}
::ng-deep #select_slb_code-panel .mat-mdc-option .mdc-list-item__primary-text p.code-description {
  font-size: 12px;
  color: #b0b0b0;
}
::ng-deep #select_slb_code-panel .mat-mdc-option:nth-child(odd) {
  background: #f0f2f3;
}
/*# sourceMappingURL=slb-code-selection-form.component.css.map */
