/* apps/shell/src/app/private/components/calculator/calculator-toggle-switch/calculator-toggle-switch.component.scss */
:host .calculator-toggle-switch {
  display: none;
}
@media screen and (min-width: 600px) {
  :host .calculator-toggle-switch {
    border: 1px solid #3891a6;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    right: 20px;
    top: 110px;
  }
}
@media screen and (min-width: 1024px) {
  :host .calculator-toggle-switch {
    right: 20px;
    top: 150px;
  }
}
@media screen and (min-width: 1800px) {
  :host .calculator-toggle-switch {
    top: 150px;
    right: 20px;
  }
}
:host .calculator-toggle-switch mat-icon {
  color: #333333;
  display: contents;
}
:host .calculator-toggle-switch:hover {
  cursor: pointer;
  background-color: #c3dee4;
}
/*# sourceMappingURL=calculator-toggle-switch.component.css.map */
