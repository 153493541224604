/* apps/shell/src/app/private/components/calculator/calculator-fee-form/calculator-fee-form.component.scss */
.input-container {
  grid-column: span 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-container .input {
  width: 48%;
}
.input-container .slide-toggle {
  width: 48%;
  padding: 18px 0;
}
.checkbox-label {
  font-weight: 600;
}
:host mat-hint,
:host .Alert_Circle {
  color: #fa7601;
}
/*# sourceMappingURL=calculator-fee-form.component.css.map */
