/* eslint-disable @typescript-eslint/naming-convention */

import { UserService, AuthService } from '@abcfinlab/auth';
import { AppConfig } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { projectIdMapByStage, supportedRealms, supportedStages } from './project.config';
import { UiStatesPreservationService } from '@abcfinlab/presentation';
import { KnownSettings } from 'libs/explorer/src/Models/KnownSettings';
import { IUserInfoDto } from '@abcfinlab/api/login';

declare global {
    interface Window {
        clarity: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {

    private readonly _stage: string | null = null;
    private readonly _authService: AuthService;
    private readonly _userService: UserService;
    private readonly _uiStatesPreservationService: UiStatesPreservationService;
    public constructor(appConfig: AppConfig, authService: AuthService, userService: UserService, uiStatesPreservationService: UiStatesPreservationService) {
        this._stage = appConfig.get('stage');
        this._authService = authService;
        this._userService = userService;
        this._uiStatesPreservationService = uiStatesPreservationService;
        this._initializationAllowed().subscribe((x) => {
            if (!x) {
                return;
            }
            if (this._isRealmSupported(x.userInfo.realm)
                && (x.isAnalyticsAllowed?.value || x.isAnalyticsAllowed === null)
                && this._isStageSupported(this._stage)) {
                this._setupScript(x.userInfo);
            }
        });

        // check for login state of the user
        this._authService.hasSession.pipe(
            filter(hasSession => !hasSession && !!window.clarity),
            first(),
        ).subscribe(() => this.unload());
    }

    public load(): void {
        this._initializationAllowed();
    }

    /**
     * Unload and reset the analytics tool
     */
    public unload(): void {
        window.clarity('stop');
        window.clarity = undefined;
    }

    private _setupScript(userInfo: IUserInfoDto): void {
        const projectID = this._getProjectID(userInfo);
        if (!projectID) {
            console.warn('Analytics: Project ID not provided / found.');
            return;
        }
        const s: HTMLScriptElement = document.createElement('script');
        s.type = 'text/javascript';
        s.innerHTML = this._generateScript(projectID);
        document.head.appendChild(s);
        window.clarity('identify', userInfo.preferredUsername);
    }

    /**
     * Check if the analytics tool is allowed to be loaded/initialized
     */
    private _initializationAllowed(): Observable<{ userInfo: IUserInfoDto; isAnalyticsAllowed: { value: boolean } }> {
        return this._userService.userInfo.pipe(
            switchMap((userInfo) => {
                if (userInfo) {
                    return this._uiStatesPreservationService.get<{ value: boolean }>(KnownSettings.USER_ANALYTICS_CONSENT).pipe(
                        map(isAnalyticsAllowed => ({ userInfo, isAnalyticsAllowed })),
                    );
                }
                return of(null);
            }),
        );
    }

    /**
     * Generate the script tag to be added to the head section of the application.
     */
    private _generateScript(projectId: string): string {
        // generate the script element tag
        return `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${projectId}");`;
    }

    /**
     * Returns the project ID for a specific group of users
     */
    private _getProjectID(userInfo: IUserInfoDto): string {
        const projectID = projectIdMapByStage.get(this._stage)[userInfo.realm];
        return projectID;
    }

    /**
     * Check if the realm provided by the auth service is supported
     */
    private _isRealmSupported(realm: string): boolean {
        if (!realm) {
            return false;
        }
        return supportedRealms.includes(realm);
    }

    private _isStageSupported(stage: string): boolean {
        if (!stage) {
            return false;
        }
        return supportedStages.includes(stage);
    }

}
