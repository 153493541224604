// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ClockWidgetViewPresenter } from './ClockWidgetViewPresenter';

// #endregion

/**
 * The `ClockWidgetView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-clock-widget-view',
    templateUrl: './ClockWidgetView.html',
    styleUrls: ['./ClockWidgetView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ClockWidgetViewPresenter,
    ],
})
export class ClockWidgetView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: ClockWidgetViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ClockWidgetView` class.
     *
     * @public
     */
    public constructor(presenter: ClockWidgetViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `ClockWidgetView`
     *
     * @public
     * @readonly
     */
    public get presenter(): ClockWidgetViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
