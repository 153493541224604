import { ContactsModule } from '@abcfinlab/contacts';
import { FeatureModule, TranslationModule } from '@abcfinlab/core';
import { GwgModule } from '@abcfinlab/gwg';
import { LayoutModule } from '@abcfinlab/layout';
import { APP_DATE_FORMATS, AppDateAdapter, CameraModule, PresentationModule } from '@abcfinlab/presentation';
import { QuoteModule } from '@abcfinlab/quote';
import { BannerModule, GreetingModule, MaxValidatorModule, MinValidatorModule, PersonaModule, SelectOnDblClickDirectiveModule, SpacerModule, TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PrivateRoutingModule } from '../../../../../../apps/shell/src/app/private/private-routing.module';
import { SharedModule as SharedModuleLb } from '../../../../../../libs/legacy/gwg/src/lib/shared/shared.module';
import { SharedModule } from '../../shared/shared.module';
import { CalculationFormComponent } from '../components/calculator/calculation-form/calculation-form.component';
import { CalculatorFeeFormComponent } from '../components/calculator/calculator-fee-form/calculator-fee-form.component';
import { CalculatorToggleSwitchComponent } from '../components/calculator/calculator-toggle-switch/calculator-toggle-switch.component';
import { CalculatorUsedConditionFormComponent } from '../components/calculator/calculator-used-condition-form/calculator-used-condition-form.component';
import { FunctionResolveComponent } from '../components/calculator/function-resolve/function-resolve.component';
import { SlbCodeSelectionFormComponent } from '../components/calculator/slb-code-selection-form/slb-code-selection-form.component';
import { LesseeListItemComponent } from '../components/lessee-list-item/lessee-list-item.component';
import { LesseeListComponent } from '../components/lessee-list/lessee-list.component';
import { SlbQuoteDetailCardComponent } from '../components/slb-quote-detail-card/slb-quote-detail-card.component';
import { CalculationComponent } from '../pages/calculation/calculation.component';
import { MoneyLaunderingComponent } from '../pages/money-laundering/money-laundering.component';
import { CamSearchResultComponent } from '../pages/route-container-create-quote/cam-search-result/cam-search-result.component';
import { CreateContractComponent } from '../pages/route-container-create-quote/create-contract.component';
import { PageChooseLesseeComponent } from '../pages/route-container-create-quote/page-choose-lessee/page-choose-lessee.component';
import { ContainerQuotesComponent } from '../pages/route-container-quotes/container-quotes.component';
import { PageAddVendorComponent } from '../pages/route-container-quotes/page-add-vendor/page-add-vendor.component';
import { QuoteDetailViewComponent } from '../pages/route-container-quotes/page-quote-detail-view/quote-detail-view.component';
import { ContainerSignContractComponent } from '../pages/route-container-sign-contract/container-sign-contract.component';
import { PageIdentityCardComponent } from '../pages/route-container-sign-contract/page-identity-card/page-identity-card.component';
import { ContactFilterPipe } from '../pipes/contact-filter/ContactFilter.pipe';

@NgModule({
    declarations: [
        CalculationComponent,
        CreateContractComponent,
        FunctionResolveComponent,
        PageIdentityCardComponent,
        ContainerSignContractComponent,
        ContainerQuotesComponent,
        MoneyLaunderingComponent,
        ContactFilterPipe,
        QuoteDetailViewComponent,
        PageAddVendorComponent,
        PageChooseLesseeComponent,
        LesseeListComponent,
        CamSearchResultComponent,
        LesseeListItemComponent,
        CalculatorToggleSwitchComponent,
        CalculatorFeeFormComponent,
        CalculatorUsedConditionFormComponent,
        CalculationFormComponent,
        SlbCodeSelectionFormComponent,
        SlbQuoteDetailCardComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        SharedModuleLb,
        PrivateRoutingModule,
        FormsModule,
        PresentationModule,
        CameraModule,
        MatFormFieldModule,
        NgxCurrencyDirective,
        MatListModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatExpansionModule,
        CameraModule,
        GwgModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatTooltipModule,
        MatRadioModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        MatTabsModule,
        NgxsFormPluginModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatBadgeModule,
        MatIconModule,
        MatRippleModule,
        FlexLayoutModule,
        ContactsModule,
        FeatureModule,
        MatCardModule,
        MatInputModule,
        MinValidatorModule,
        MaxValidatorModule,
        GreetingModule,
        PersonaModule,
        SelectOnDblClickDirectiveModule,
        MatChipsModule,
        QuoteModule,
        LayoutModule,
        BannerModule,
        TextBlockModule,
        SpacerModule,
        TranslationModule,
    ],
    providers: [
        provideNgxMask(),
        {
            provide: DateAdapter, useClass: AppDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS,
        },
    ],
})
export class LegacyModule {
}
