/* apps/shell/src/app/private/components/calculator/function-resolve/function-resolve.component.scss */
:host {
  display: block;
  width: 100%;
}
:host .function-resolve {
  display: flex;
  width: 100%;
  margin: 40px 0;
}
:host .function-resolve mat-chip-listbox {
  margin-bottom: 10px;
}
:host .function-resolve mat-chip {
  font-size: 16px;
  padding: 18px;
  cursor: pointer;
}
:host .function-resolve mat-chip.mat-chip-disabled {
  pointer-events: none;
}
@media screen and (min-width: 1024px) {
  :host .function-resolve {
    margin: unset;
    justify-content: flex-start;
  }
}
/*# sourceMappingURL=function-resolve.component.css.map */
